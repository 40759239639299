import { Component, OnInit } from '@angular/core';
import { ThemeService } from '@core/services/theme/theme.service';
import { Link } from '@shared/models/link';

@Component({
  selector: 'app-layout-footer',
  templateUrl: './layout-footer.component.html',
  styleUrls: ['./layout-footer.component.scss']
})

export class LayoutFooterComponent implements OnInit {

  links: Link[];
  currentYear: number;

  constructor(
    public themeService: ThemeService,
  ) { }

  ngOnInit(): void {
    this.getCurrentYear();
    this.getFooterLinks();
  }

  getFooterLinks(): void {
    this.links = this.themeService.theme.footerLinks;
  }

  getCurrentYear(): void {
    this.currentYear = new Date().getFullYear();
  }

}
