import { Link } from "src/app/shared/models/link";

export const FOOTER_LINKS_PV: Link[] = [
  {
    label: 'SCREEN_PRECHECKIN_LAYOUT.FOOTER_LEGAL_NOTICE',
    href: 'SCREEN_PRECHECKIN_LAYOUT.PV_URL_FOOTER_LEGAL_NOTICE'
  },
  {
    label: 'SCREEN_PRECHECKIN_LAYOUT.FOOTER_PRIVACY_NOTICE_COOKIES',
    href: 'SCREEN_PRECHECKIN_LAYOUT.PV_URL_FOOTER_PRIVACY_NOTICE_COOKIES'
  },
  {
    label: 'SCREEN_PRECHECKIN_LAYOUT.FOOTER_GENERAL_TERMS_SALES',
    href: 'SCREEN_PRECHECKIN_LAYOUT.PV_URL_FOOTER_GENERAL_TERMS_SALES'
  },
  {
    label: 'SCREEN_PRECHECKIN_LAYOUT.FOOTER_GENERAL_TERMS_USE',
    href: 'SCREEN_PRECHECKIN_LAYOUT.PV_URL_FOOTER_GENERAL_TERMS_USE'
  }
]