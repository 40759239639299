import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { LayoutHeaderComponent } from './header/layout-header.component';
import { LayoutFooterComponent } from './footer/layout-footer.component';
import { RouterModule } from '@angular/router';
import { LayoutSymbolsComponent } from './layout-symbols/layout-symbols.component';
import { TranslateModule } from '@ngx-translate/core';
import { LangSelectModule } from '@shared/modules/lang-select/lang-select.module';


@NgModule({
  declarations: [
    LayoutComponent,
    LayoutHeaderComponent,
    LayoutFooterComponent,
    LayoutSymbolsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    LangSelectModule,
  ]
})
export class LayoutModule { }
