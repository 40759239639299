import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackTypeEnum } from './models/snackbar.enum';
import { SnackbarOptions } from './models/snackbar.model';
import { SnackbarComponent } from './snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  public open(snackbarOptions: any) {
    const defaultOptions = new SnackbarOptions();
    const options : SnackbarOptions = {
      ...defaultOptions,
      ...snackbarOptions
    }

    const panelClass = ['precheckin-snackbar', options.type];
    if(options.size === 'lg') {
      panelClass.push('precheckin-lg');
    }

    this.snackBar.openFromComponent(SnackbarComponent, {
      panelClass,
      duration: options.duration,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      data: { 
        message: options.message, 
        type: options.type, 
        snackBar: this.snackBar 
      }
    });
  }

  public openError(err: any) {
    let params = {};
    if(
      err?.error?.messages
      && err.error.messages.length
      && err.error.messages[0].code
    ) {
      params = {
        message: err.error.messages[0].code, 
        type: SnackTypeEnum.ERROR
      }
    }
    this.open(params);
  }

  public clear(): void {
    this.snackBar.dismiss();
  }

  get snackbarRef(): MatSnackBarRef<any> {
    return this.snackBar._openedSnackBarRef;
  }
}
