import { Component, OnDestroy, OnInit } from '@angular/core';
import { SnackbarService } from '@core/modules/snackbar/snackbar.service';
import { UserService } from '@core/services/user.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss']
})
export class CompletedComponent implements OnInit, OnDestroy {

  stop$: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private snackbar: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.logout();
  }
  
  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  logout(): void {
    this.userService.authLogout().pipe(
      takeUntil(this.stop$)
    ).subscribe({
      next: () => {
        this.userService.logout(false);
      },
      error: () => {
        this.snackbar.clear();
        this.userService.logout(false);
      }
    });
  }

}
