export class DialogOptions {
  constructor(
    public title: string = 'PRECKECKIN_DIALOG.TITLE_CONFIRM_DEFAULT',
    public message: string = null,
    public confirm: boolean = false,
    public confirmText: string = 'SCREEN_PRECHECKIN_COMMON.CONFIRM',
    public cancelText: string = 'SCREEN_PRECHECKIN_COMMON.CANCEL',
    public okText: string = 'SCREEN_PRECHECKIN_COMMON.OK',
    public icon: string = null,
  ) {}
}