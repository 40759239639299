import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LayoutModule } from './core/modules/layout/layout.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslatePmsLoader } from './core/services/translate-pms-loader';
import { environment } from '@env/environment';
import { SnackbarModule } from './core/modules/snackbar/snackbar.module';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { EnsureHttpsInterceptor } from './core/interceptors/ensure-https.interceptor';
import { RefreshTokenInterceptor } from './core/interceptors/refresh-token.interceptor';
import { ErrorsInterceptor } from './core/interceptors/errors.interceptor';
import { AutoAuthComponent } from './core/components/auto-auth/auto-auth.component';
import { PaymentFailedModule } from './modules/app-pages/payment-failed/payment-failed.module';
import { CompletedModule } from './modules/app-pages/completed/completed.module';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CUSTOM_MOMENT_DP_FORMATS } from '@core/consts/moment-dp-formats';
import { LoaderComponent } from './core/components/loader/loader.component';
import { LoaderInterceptor } from '@core/interceptors/loader.interceptor';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslatePmsLoader(
    http, 
    [
      'SCREEN_PRECHECKIN_COMMON', 
      'SCREEN_PRECHECKIN_LAYOUT', 
      'SCREEN_PRECHECKIN_SNACKBAR',
      // TODO : Screens under should be lazy loaded instead.
      // Currently lazy load works on page loading but not when lang is dynamicly changed
      'SCREEN_PRECHECKIN_NAVIGATION',
      'SCREEN_PRECHECKIN_HOME',
      'SCREEN_PRECHECKIN_GUESTS',
      'SCREEN_PRECHECKIN_SUMMARY',
      'SCREEN_PRECHECKIN_CONFIRMATION',
      'SCREEN_PRECHECKIN_LOGIN',
      'SCREEN_PRECHECKIN_APP_PAGES',
    ], 
    environment.api.urlWording
  );
}

@NgModule({
  declarations: [
    AppComponent,
    AutoAuthComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false,
    }),
    AppRoutingModule,
    LayoutModule,
    SnackbarModule,
    PaymentFailedModule,
    CompletedModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: EnsureHttpsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_DP_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
