import { Step } from "src/app/modules/precheckin/models/step";

export class User {
  constructor(
    public customer: UserData = null,
    public steps: Step[] = null,
    public token: string = null,
  ) {}
}

export interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  initials: string;
  title: string;
  // bookingNumber: number;
}