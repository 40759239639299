import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadPictogramComponent } from './components/head-pictogram/head-pictogram.component';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from './modules/dialog/dialog.module';



@NgModule({
  declarations: [
    HeadPictogramComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DialogModule,
    // MaterialModule,
  ],
  exports: [
    HeadPictogramComponent,
  ]
})
export class SharedModule { }
