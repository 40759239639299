<div class="lang-select-container d-flex align-items-center">
  <mat-form-field>
    <mat-select 
      class="lang-select mat-select-custom-arrow-trigger" 
      [panelClass]="'lang-select-panel'"
      [(value)]="selectedLang" 
      (selectionChange)="changeLanguage($event)"
    >
      <mat-select-trigger>

        <div class="trigger-container">
          <div class="trigger-content">
            <img class="lang-selected-picture" src="assets/img/flags/{{selectedLang}}-disc.png" alt="{{selectedLang}}" />
          </div>
          <div class="trigger-arrow">
            <svg class="icon-12 text-white">
              <use href="#icon-chevron-down" />
            </svg>
          </div>
        </div>

      </mat-select-trigger>
      <mat-option *ngFor="let lang of langs" [value]="lang.value">
        <div class="lang-select-option text-uppercase">
          <span class="mini-flag me-2">
            <img src="assets/img/flags/{{lang.value}}-disc.png" alt="{{lang.label}}" />
          </span>
          {{lang.label}}
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
