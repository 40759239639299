import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompletedComponent } from './completed.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { TranslateResolver } from '@core/resolver/translate.resolver';
import { TranslatePmsLoader } from '@core/services/translate-pms-loader';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslatePmsLoader(http, ['SCREEN_PRECHECKIN_APP_PAGES'], environment.api.urlWording);
}
const routes: Routes = [
  { 
    path: '', 
    resolve: {translate: TranslateResolver},
    component: CompletedComponent 
  }
];

@NgModule({
  declarations: [
    CompletedComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      extend: true
    }),
    SharedModule,
  ],
  providers: [TranslateResolver]
})
export class CompletedModule { }
