import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { StepPath } from '@modules/precheckin/enums/stepPath.enum';
import { User } from '../models/user.model';
import { ThemeService } from './theme/theme.service';
import { environment } from '@env/environment';
import { DateAdapter } from '@angular/material/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _user: User;
  private _lang: string;
  private _langChangeSubject: Subject<string> = new Subject<string>();

  langChange$ = this._langChangeSubject.asObservable();
  redirectUrl: string;
  redirectBookingNumber: string;
  
  constructor(
    private themeService: ThemeService,
    private http: HttpClient,
    private router: Router,
    public translate: TranslateService,
    private adapter: DateAdapter<any>,
  ) { }

  // Summary
    // get user() => private _user
    // getToken()

    // authLogin()
    // authAutoLogin()
    // authLogout()

    // login()
    // logout()
    // checkTokenValidity()
    // refreshToken()

  get user(): User {
    if (!this._user && sessionStorage.getItem('precheckin_user')) {
      let user = JSON.parse(sessionStorage.getItem('precheckin_user') as string);
      this._user = Object.assign(new User(), user);
    }
    return this._user;
  }

  get lang(): string {
    if(!this._lang && sessionStorage.getItem('precheckin_lang')) {
      const userLang = sessionStorage.getItem('precheckin_lang');
      this._lang = userLang;
    }
    return this._lang;
  }

  getToken(): string {
    return this.user?.token;
  }

  ///////////////////////////////////////////////////////////////////////
  // APIs
  ///////////////////////////////////////////////////////////////////////

  authLogin(lastName: string, email: string, bookingNumber: string): Observable<any> {
    const body: any = {
      host: this.themeService.theme.host,
      env: environment.env,
      languageCode: this.lang,
      lastName,
      email,
      bookingNumber,
    }
    return this.http.post(`${environment.api.urlPrecheckin}/auth`, body);
  }
  
  authAutoLogin(sso: string, languageCode: string): Observable<any> {
    const body: any = {
      host: this.themeService.theme.host,
      env: environment.env,
      languageCode,
      sso
    }
    return this.http.post(`${environment.api.urlPrecheckin}/auth`, body);
  }

  authLogout(): Observable<any> {
    return this.http.get(`${environment.api.urlPrecheckin}/logout`);
  }

  refreshToken(): Observable<{token: string}> {
    return this.http.get(`${environment.api.urlPrecheckin}/refresh-token`).pipe(
      map((data: any): {token: string} => {
        this._user.token = data.data.token;
        sessionStorage.setItem('precheckin_user', JSON.stringify(this._user));
        return data.data as {token: string};
      })
    )
  }

  ///////////////////////////////////////////////////////////////////////
  // Methods
  ///////////////////////////////////////////////////////////////////////

  login(data: User, goToFirstStep = true): void {
    this._user = new User();
    this._user = data;
    this._user.steps = this._user.steps.sort((a, b) => a.position - b.position);
    sessionStorage.setItem('precheckin_user', JSON.stringify(this._user));

    const jwtHelper = new JwtHelperService();
    const loginBookingNumber = jwtHelper.decodeToken(data.token).bookingNumber;

    if(this.redirectUrl && this.redirectBookingNumber === loginBookingNumber) {
      this.router.navigate(
        [this.redirectUrl], 
        {replaceUrl: true} // Avoid auto-auth urls to be saved in browser history
      );
      return;
    }
    
    if(goToFirstStep) {
      this.goToFirstStep();
    } else {
      this.router.navigate(
        ['/home'], 
        {replaceUrl: true} // Avoid auto-auth urls to be saved in browser history
      );
    }
  }
  
  logout(redirect: boolean = true): void {
    sessionStorage.clear();
    this._user = null;
    if(redirect) {
      this.router.navigate(['/home']);
    }
  }

  goToFirstStep(): void {
    const firstStepCode = this.user.steps.find(step => step.code !== 'HOME').code as keyof typeof StepPath;
    this.router.navigate([StepPath[firstStepCode]]);
  }

  setUserLang(lang: string | undefined = undefined) {
    const langsRegex = /en|fr|de|nl|it|es/;
    
    if(lang?.match(langsRegex)) {
      this._lang = lang;
      this.setTranslation();
      return;
    }

    if(this.lang) {
      this.setTranslation();
      return;
    }

    const browserLang = this.translate.getBrowserLang();
    if(browserLang.match(langsRegex)) {
      this._lang = browserLang;
      this.setTranslation();
      return;
    }
    
    this._lang = 'en';
    this.setTranslation();
  }

  setTranslation(): void {
    this.translate.currentLang = '';
    this.translate.use(this.lang);
    this.adapter.setLocale(this.lang);
    sessionStorage.setItem('precheckin_lang', this._lang);
    
    this._langChangeSubject.next(this.lang);
  }

}
