import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from './core/services/theme/theme.service';
import { UserService } from './core/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    public themeService: ThemeService,
    public translate: TranslateService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.themeService.loadTheme();
    this.initLang();
  }

  initLang(): void {
    this.translate.addLangs(['en', 'fr', 'de', 'nl', 'it']);
    this.translate.setDefaultLang('en');
    this.userService.setUserLang();
  }

  
}
