<header id="header" class="d-flex justify-content-between align-items-center">
  <div class="header-start-side">
    <a class="logo d-block" [routerLink]="['/']">
      <img [attr.src]="theme.logo" [attr.alt]="theme.brandName" />
    </a>
    <div class="welcome">
      {{'SCREEN_PRECHECKIN_LAYOUT.HEADER_WELCOME' | translate: {brand: theme.brandName ? theme.brandName : '' } }}
    </div>
  </div>
  <div class="header-end-side d-flex align-items-center">
    <app-lang-select *ngIf="lang" [selectedLang]="lang"></app-lang-select>
  </div>
</header>
