import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "@core/services/user.service";
import { getErrorCode } from "@shared/helpers/helpers";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { SnackTypeEnum } from "../modules/snackbar/models/snackbar.enum";
import { SnackbarService } from "../modules/snackbar/snackbar.service";

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  
  constructor(
    private snackbar: SnackbarService,
    private router: Router,
    private userService: UserService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorCode = getErrorCode(error);
        const translatedErrors: string[] = [
          'ERROR_PAYMENT',
          'PAYMENT_REFUSED',
          'PAYMENT_CANCELED',
        ];
        const handledErrors: string[] = [
          'INVALID_IDENTIFIER' // Login component
        ]

        // ERROR : EXPIRED TOKEN
        if(errorCode === 'EXPIRED_TOKEN') {
          this.snackbar.open({
            type: SnackTypeEnum.ERROR,
            message: 'SCREEN_PRECHECKIN_SNACKBAR.ERROR_EXPIRED_TOKEN',
          });
          this.userService.logout();
          return throwError(() => error);
        }

        if(errorCode === 'PRECHECKIN_COMPLETE') {
          this.router.navigate(['/check-in-complete']);
          return throwError(() => error);
        }

        if(errorCode === 'PREVIOUS_STEP_VALIDATION_ERR') {
          this.router.navigate(['/precheckin']);
          this.snackbar.open({
            type: SnackTypeEnum.ERROR,
            message: 'SCREEN_PRECHECKIN_SNACKBAR.ERROR_PREVIOUS_STEP_VALIDATION_ERR',
          });
          return throwError(() => error);
        }

        if(errorCode === 'PRECHECKIN_NOT_AVAILABLE') {
          this.snackbar.open({
            type: SnackTypeEnum.ERROR,
            message: 'SCREEN_PRECHECKIN_SNACKBAR.ERROR_PRECHECKIN_NOT_AVAILABLE',
            duration: 10000,
          });
          return throwError(() => error);
        }

        if(translatedErrors.includes(errorCode)) {
          this.snackbar.open({
            type: SnackTypeEnum.ERROR,
            message: 'SCREEN_PRECHECKIN_SNACKBAR.ERROR_' + errorCode,
          });
          return throwError(() => error);
        }

        // ALREADY HANDLED ERRORS
        if(handledErrors.includes(errorCode)) {
          return throwError(() => error);
        }

        // ERROR : 401 (UNAUTHORIZED)
        if(error.status === 401) {
          this.snackbar.open({
            type: SnackTypeEnum.ERROR,
            message: 'SCREEN_PRECHECKIN_SNACKBAR.ERROR_401_UNAUTHORIZED',
          });
          return throwError(() => error);
        }

        ///////////////////////////////
        // ELSE
        this.snackbar.open({
          type: SnackTypeEnum.ERROR,
          message: 'SCREEN_PRECHECKIN_SNACKBAR.ERROR_DEFAULT_MESSAGE',
        });
        
        return throwError(() => error);
      })
    );
  }

}