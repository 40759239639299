import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs";
import { SnackTypeEnum } from "../modules/snackbar/models/snackbar.enum";
import { SnackbarService } from "../modules/snackbar/snackbar.service";
import { UserService } from "../services/user.service";


@Injectable({
  providedIn: 'root'
})
export class IsSignedInGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private snackbar: SnackbarService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken(this.userService.user.token);

    this.userService.redirectBookingNumber = decodedToken.bookingNumber;
    this.userService.redirectUrl = state.url;

    
    const isLoggedIn = !!this.userService.user;

    if (!isLoggedIn) {
      this.userService.logout();
      return false;
    } else {

      // Check Token validity
      const helper = new JwtHelperService();
      const expirationDate = helper.getTokenExpirationDate(this.userService.user.token);

      if (expirationDate < new Date()) {
        this.snackbar.open({
          type: SnackTypeEnum.ERROR,
          message: 'SCREEN_PRECHECKIN_SNACKBAR.ERROR_EXPIRED_TOKEN',
        });
        this.userService.logout();
        return false;
      }
    }

    return true
  }
}
