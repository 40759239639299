import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './payment-failed.component.html',
  styleUrls: ['./payment-failed.component.scss']
})
export class PaymentFailedComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {}

}
