import { FormGroup } from "@angular/forms";

export const formHasError = (form: FormGroup, controlName: string, errorName: string | null = null): boolean => {
  const control = form.controls[controlName];
  if(errorName) {
    return control.hasError(errorName) && control.touched;
  }
  // Form field has any error
  return !!control.errors
}

export const formatBirthDate = (birthdate: string, lang: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }
  const date = new Date(birthdate);
  const locale = lang === 'en' ? 'en-GB' : lang;
  return date.toLocaleDateString(locale, options);
}

export const setBodyNoScroll = (active: boolean): void => {
  const html = document.querySelector('html');
  const body = document.querySelector('body');

  // De-activate body scroll and keep scrolled position
  if (active) {
    const top = html.scrollTop;
    html.classList.add('no-scroll');
    body.style.top = `-${top}px`;
    return;
  }

  // Re-activate body scroll and restore initial scrolled content
  html.classList.remove('no-scroll');
  const bodyStyleTop = body.style.top;
  if(bodyStyleTop) {
    const initialScrollY = parseInt(body.style.top, 10) * -1;
    window.scrollTo({
      top: initialScrollY,
      behavior: 'instant' as ScrollBehavior
    });
  }
  body.style.removeProperty('top');
}

export const format2decimals = (num: number): string => {
  return Number(num).toFixed(2);
}

export const getErrorCode = (err: any): string => {
  return (err?.error?.messages && err.error.messages.length && err.error.messages[0].code) ? err.error.messages[0].code : null;
}

export const setFormFieldsError = (form: FormGroup, fields: string[], errorValue: any) => {
  fields.forEach(field => {
    form.get(field).setErrors(errorValue);
  })
}

export const getAgeFromBirthday = (birthDate: string, endDate: string): number => {
  let age = null;
  if (birthDate && endDate) {
    const start = new Date(birthDate);
    const end = new Date(endDate);
    age = end.getFullYear() - start.getFullYear();
    let m = end.getMonth() - start.getMonth();
    if (m < 0 || (m === 0 && end.getDate() < start.getDate())) {
      age--;
    }
  }
  return age;
}
