<div class="page-content confirmation">
  <div class="precheckin-card card-xs mx-auto">
    <div class="text-center mb-4">
      <svg class="icon-xl text-error mb-3"><use href="#icon-warning-circle" /></svg>
      <p>{{ 'SCREEN_PRECHECKIN_APP_PAGES.PAYMENT_FAILED_OUPS' | translate }}</p>
    </div>
    <a class="btn-checkin-error w-100" [routerLink]="['/precheckin/summary']" >
      <svg class="icon-sm me-2"><use href="#icon-arrow-left" /></svg>
      {{ 'SCREEN_PRECHECKIN_APP_PAGES.PAYMENT_FAILED_BACK' | translate }}
    </a>
  </div>
</div>
