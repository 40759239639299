import { FOOTER_LINKS_PV } from "./footer/footer.const";
import { Theme } from "./theme.model";

export const THEMES: Theme[] = [
  {
    host: [
      'default' // Default theme
    ],
    name: 'theme-pv',
    brand: 'pv',
    brandName: 'Pierre & Vacances',
    brandNameShort: 'P&V',
    logo: 'assets/img/pv/logo-pv.png',
    logoAlt: 'assets/img/pv/logo-pv-alt.png',
    appFavicon: 'assets/img/pv/favicon.png',
    appTitle: 'Pierre & Vacances - Pre-Checkin',
    footerLinks: FOOTER_LINKS_PV,
  },
  {
    host: [
      'localhost',
      'precheckin-dev.pierreetvacances.com', // dev
      'precheckin-test.pierreetvacances.com', // rct
      'precheckin.pierreetvacances.com' // prod
    ],
    name: 'theme-pv',
    brand: 'pv',
    brandName: 'Pierre & Vacances',
    brandNameShort: 'P&V',
    logo: 'assets/img/pv/logo-pv.png',
    logoAlt: 'assets/img/pv/logo-pv-alt.png',
    appFavicon: 'assets/img/pv/favicon.png',
    appTitle: 'Pierre & Vacances - Pre-Checkin',
    footerLinks: FOOTER_LINKS_PV,
  },
]
