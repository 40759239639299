import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { LayoutService } from './layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements OnInit {

  constructor(
    public layoutService: LayoutService,
  ) { }

  ngOnInit(): void {
    this.layoutService.setEmbedMode();
  }

}
