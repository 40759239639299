import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoAuthComponent } from './core/components/auto-auth/auto-auth.component';
import { IsSignedInGuard } from './core/guards/is-signed-in.guard';
import { LayoutComponent } from './core/modules/layout/layout.component';

const appRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      {
        path: 'auth',
        component: AutoAuthComponent,
      },
      {
        path: 'home',
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) 
      },
      { 
        path: 'precheckin', 
        canActivate: [IsSignedInGuard],
        loadChildren: () => import('./modules/precheckin/precheckin.module').then(m => m.PrecheckinModule) 
      },
      { 
        path: 'payment-failed',
        loadChildren: () => import('./modules/app-pages/payment-failed/payment-failed.module').then(m => m.PaymentFailedModule)
      },
      { 
        path: 'check-in-complete',
        loadChildren: () => import('./modules/app-pages/completed/completed.module').then(m => m.CompletedModule)
      },
    ]
  },
  { 
    path: '**', 
    component: LayoutComponent ,
    loadChildren: () => import('./modules/app-pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        scrollPositionRestoration: 'enabled', 
        onSameUrlNavigation: 'reload'
      },
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
