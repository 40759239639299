import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-head-pictogram',
  templateUrl: './head-pictogram.component.html',
  styleUrls: ['./head-pictogram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadPictogramComponent {

  @Input() icon: string;
  @Input() label: string;
  @Input() animate: string;

  constructor() { }

  get animateClass() {
    return this.animate ? `animate-${this.animate}` : null;
  }

}
