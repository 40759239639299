import { Component, Input } from '@angular/core';
import { UserService } from '@core/services/user.service';
import { Lang } from '../../models/lang';

@Component({
  selector: 'app-lang-select',
  templateUrl: './lang-select.component.html',
  styleUrls: ['./lang-select.component.scss']
})

export class LangSelectComponent {

  @Input() selectedLang: string;
  
  langs: Lang[] = [
    { value: 'fr', label: 'fr' },
    { value: 'en', label: 'en' },
    { value: 'de', label: 'de' },
    { value: 'nl', label: 'nl' },
    { value: 'it', label: 'it' },
    { value: 'es', label: 'es' },
  ];

  constructor(
    private userService: UserService,
  ) { }

  changeLanguage(event: any) {
    this.userService.setUserLang(event.value);
  }

}
