<div *ngIf="isLoading" class="loader-container">
  <div class="loader-layer"></div>
  <div class="loader-spinner">
    <svg class="icon-xl"><use href="#spinner" /></svg>
  </div>
</div>

<!-- On page refresh, layout-symbols component isn't fully charged, so we use symbol directly here -->
<svg style="display: none">
  <symbol id="spinner" viewBox="0 0 40 40">
    <path fill="currentColor" d="M9.601 28.4c-1 0-1.85.35-2.55 1.05C6.35 30.15 6 31 6 32c0 1 .35 1.85 1.05 2.55.7.7 1.55 1.05 2.55 1.05.983 0 1.83-.35 2.538-1.05.708-.7 1.062-1.55 1.062-2.55 0-1-.354-1.85-1.062-2.55-.709-.7-1.555-1.05-2.538-1.05ZM8.8 20.8c0-1.1-.391-2.041-1.175-2.825C6.842 17.19 5.9 16.8 4.8 16.8s-2.042.392-2.825 1.175C1.192 18.758.8 19.7.8 20.8s.392 2.041 1.175 2.825C2.758 24.408 3.7 24.8 4.8 24.8s2.042-.392 2.825-1.175C8.41 22.842 8.8 21.9 8.8 20.8ZM31.999 11.6c.55 0 1.02-.196 1.412-.588.392-.391.588-.862.588-1.412 0-.55-.196-1.021-.588-1.413a1.925 1.925 0 0 0-1.412-.587c-.55 0-1.021.196-1.413.587a1.926 1.926 0 0 0-.587 1.413c0 .55.195 1.02.587 1.412.392.392.863.588 1.413.588ZM9.6 5.2c-1.216 0-2.254.43-3.112 1.288C5.629 7.346 5.2 8.383 5.2 9.6s.43 2.254 1.288 3.113C7.347 13.57 8.384 14 9.6 14c1.216 0 2.254-.43 3.112-1.287C13.57 11.854 14 10.817 14 9.6s-.429-2.254-1.287-3.112C11.855 5.629 10.817 5.2 9.6 5.2ZM38.5 19.1a2.313 2.313 0 0 0-1.7-.7c-.667 0-1.234.233-1.7.7a2.316 2.316 0 0 0-.7 1.7c0 .666.233 1.234.7 1.7.466.466 1.033.7 1.7.7.666 0 1.233-.234 1.7-.7.466-.467.7-1.034.7-1.7 0-.667-.233-1.234-.7-1.7ZM32 29.2c-.767 0-1.426.274-1.976.824A2.695 2.695 0 0 0 29.2 32c0 .766.276 1.425.825 1.975.55.55 1.209.825 1.976.825.766 0 1.425-.275 1.975-.825.55-.55.825-1.208.825-1.975 0-.767-.275-1.425-.825-1.976A2.693 2.693 0 0 0 32 29.2ZM20.8 33.599c-.883 0-1.638.313-2.263.938A3.084 3.084 0 0 0 17.6 36.8c0 .883.312 1.637.937 2.262S19.917 40 20.8 40s1.637-.313 2.262-.938A3.082 3.082 0 0 0 24 36.8c0-.884-.313-1.638-.938-2.263A3.083 3.083 0 0 0 20.8 33.6ZM20.8 0c-1.333 0-2.467.466-3.4 1.4-.933.933-1.4 2.067-1.4 3.4 0 1.333.466 2.466 1.4 3.4.933.933 2.067 1.4 3.4 1.4 1.334 0 2.467-.467 3.4-1.4.933-.933 1.4-2.067 1.4-3.4 0-1.333-.467-2.466-1.4-3.4C23.267.467 22.134 0 20.8 0Z"/>
  </symbol>
</svg>
