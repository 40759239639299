import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import {Injectable} from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import * as moment from "moment";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { UserService } from "../services/user.service";

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenInterceptor implements HttpInterceptor {
  refreshInProgress = false;

  constructor(
    private userService: UserService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
          tap(() => {
            
            if (this.userService.user) {

              const helper = new JwtHelperService();
              const expirationDate = helper.getTokenExpirationDate(this.userService.user.token);
              const dateNow = new Date();
              const dateRefresh = moment(expirationDate).subtract(4, 'minutes').toDate();

              if (
                dateNow < expirationDate
                && dateNow > dateRefresh 
                && !req.url.includes('refresh-token') 
                && !this.refreshInProgress
              ) {
                this.refreshInProgress = true;
                this.userService.refreshToken().subscribe(
                  () => this.refreshInProgress = false
                );
              }
            }

          })
      )
  }
}