<div class="page-content completed">
  <app-head-pictogram [icon]="'icon-confirm'" [label]="'SCREEN_PRECHECKIN_APP_PAGES.COMPLETED_TITLE'"></app-head-pictogram>
  <div class="precheckin-card card-xs mx-auto">
    <div class="text-center mb-4">
      <svg class="icon-lg text-secondary mb-3"><use href="#icon-success" /></svg>
      <p>{{ 'SCREEN_PRECHECKIN_APP_PAGES.PRECHECKIN_ALREADY_COMPLETED' | translate }}</p>
    </div>
    <a class="btn-checkin w-100" [routerLink]="['/home']" >
      <svg class="icon-sm me-2"><use href="#icon-arrow-left" /></svg>
      {{ 'SCREEN_PRECHECKIN_APP_PAGES.BACK_TO_HOME' | translate }}
    </a>
  </div>
</div>
