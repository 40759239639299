export enum SnackTypeEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}

export enum SnackDurationEnum {
  SHORT = 1500,
  DEFAULT = 3500,
  LONG = 5000
}