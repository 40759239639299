import { Component, OnInit } from '@angular/core';
import { Theme } from '@core/services/theme/theme.model';
import { ThemeService } from '@core/services/theme/theme.service';
import { UserService } from '@core/services/user.service';

@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss']
})
export class LayoutHeaderComponent implements OnInit {

  theme: Theme = null;
  lang: string = null;

  constructor(
    private themeService: ThemeService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.getThemeInfos();
    this.getLang();
  }

  getThemeInfos(): void {
    this.theme = this.themeService.theme;
  }

  getLang(): void {
    this.lang = this.userService.lang;
  }

}
