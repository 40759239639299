<div class="precheckin-dialog">
  
  <h2 *ngIf="title" mat-dialog-title class="bm-3">
    <svg *ngIf="icon" class="icon-lg text-secondary me-1"><use [attr.href]="'#' + icon" /></svg>
    {{ title | translate }}
  </h2>

  <mat-dialog-content>
    {{ message | translate }}
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="d-flex justify-content-end w-100 pb-4">
      
      <ng-container *ngIf="isConfirm">
        <button mat-button mat-dialog-close class="btn-checkin-grey mt-3">
          {{ cancelText | translate }}
        </button>
        <button mat-button [mat-dialog-close]="true" class="btn-checkin ms-3 mt-3">
          {{ confirmText | translate }}
        </button>
      </ng-container>

      <ng-container *ngIf="!isConfirm">
        <button mat-button mat-dialog-close class="btn-checkin mt-3 w-100">
          {{ okText | translate }}
        </button>
      </ng-container>
      
    </div>
  </mat-dialog-actions>

</div>