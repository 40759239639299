<footer id="footer">
  <ul>
    <ng-container *ngIf="links">
      <li *ngFor="let link of links" class="footer-link">
        <a [attr.href]="[link.href | translate]" target="_blank">{{link.label | translate}}</a>
      </li>
    </ng-container>
    <li class="footer-credits">
      ©{{currentYear}} {{themeService.theme.brandName}}. {{ 'SCREEN_PRECHECKIN_LAYOUT.FOOTER_ALL_RIGHTS_RESERVED' | translate}}
    </li>
  </ul>
</footer>
