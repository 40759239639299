// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const host = 'https://onsite360-dev1.pvcp.intra';
// const host = 'https://onsite360-dev1-pb.pvcp.intra';
const host = 'https://api-dev.guestup.fr';

export const environment = {
  production: false,
  env: 'DEV',
  adyen: {
    environment : 'test',
    client_key: 'test_LI647MQG5ZANJDR7TV5LOEXZHU77B75R',
  },
  api: {
    urlWording: `${host}/wordings/v1`,
    urlPrecheckin: `${host}/precheckin/v1`,
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
