import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../services/user.service';

@Component({
  template: ''
})
export class AutoAuthComponent implements OnInit, OnDestroy {

  stop$: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.autoAuth();
  }
  
  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  autoAuth(): void {
    const ssoToken = this.route.snapshot.queryParamMap.get('sso');
    const ssoLang = this.route.snapshot.queryParamMap.get('language');

    if(ssoLang !== this.userService.lang) {
      this.userService.setUserLang(ssoLang);
    }

    if(ssoToken) {
      const languageCode = ssoLang ? ssoLang : this.userService.lang;

      this.userService.authAutoLogin(ssoToken, languageCode).pipe(
        takeUntil(this.stop$)
      ).subscribe({
        next: (response) => {
          this.userService.login(response.data, false);
        },
        error: () => {
          this.router.navigate(['/home']);
        }
      });
      return;
    }

    this.router.navigate(['/home']);
  }

}
