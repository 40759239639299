import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogOptions } from '../models/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  title: string;
  message: string;
  isConfirm = false;
  confirmText: string;
  cancelText: string;
  okText: string;
  icon: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogOptions,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.isConfirm = this.data.confirm;
    this.title = this.data.title;
    this.message = this.data.message;
    this.confirmText = this.data.confirmText;
    this.cancelText = this.data.cancelText;
    this.okText = this.data.okText;
    this.icon = this.data.icon;
  }


}
