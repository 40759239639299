import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { TranslatePmsLoader } from "@core/services/translate-pms-loader";
import { Injectable } from "@angular/core";
import { UserService } from "@core/services/user.service";

@Injectable()
export class TranslateResolver implements Resolve<any> {

  private currentLang: string;

  constructor(
    private translateService: TranslateService,
    private userService: UserService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const loader: TranslatePmsLoader = this.translateService.currentLoader as TranslatePmsLoader;
    const translation = this.translateService.instant(loader.page);
    const isAlreadyLoaded = translation[loader.page[0]] !== loader.page[0] && this.userService.lang === this.currentLang;

    if (!isAlreadyLoaded && this.translateService.currentLang) {
      this.currentLang = this.translateService.currentLang;
      this.translateService.currentLang = '';
      return this.translateService.use(this.currentLang);
    } else {
      return of(true);
    }
  }
}
